<template>
  <v-card shaped>
    <v-card-title>
      <v-spacer></v-spacer>
      <menuPerfilComponent
        @cambiarImagen="cambiarImagen = true"
        @modificarNombre="modificarNombre = true"
        @modificarContrasena="modificarContrasena = true"
      />
    </v-card-title>

    <v-col class="justify-center text-center">
      <v-avatar size="250" style="margin-top: -30px" tile>
        <v-icon v-if="!userAvatar" size="230">mdi-account</v-icon>
        <img v-else :src="`${imageURL}/avatar/${userAvatar}`" alt="UserImage" />
      </v-avatar>
    </v-col>

    <v-col class="justify-center" cols="12">
      <v-list dense>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ nombreCompleto }}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">mdi-at</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ correo }}</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item>
          <v-list-item-icon>
            <v-icon color="deep-purple darken-2">mdi-discord</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Prueba123</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-col>

    <modificarNombreComponent
      v-if="modificarNombre"
      :mostrar="modificarNombre"
      :nombre="nombre"
      :paterno="paterno"
      :materno="materno"
      @cancelar="modificarNombre = false"
      @nombreModificado="nombreModificado"
    />

    <modificarContrasenaComponent
      v-if="modificarContrasena"
      :mostrar="modificarContrasena"
      @cancelar="modificarContrasena = false"
      @contrasenaModificada="contrasenaModificada"
    />

    <cambiarImagenComponent 
      v-if="cambiarImagen"
      :mostrar="cambiarImagen"
      @cancelar="cambiarImagen = false"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getDatosUsuarioService } from "./perfil.service";

import menuPerfilComponent from "./menuPerfil.vue";
import modificarNombreComponent from "./modificarNombre.vue";
import modificarContrasenaComponent from "./modificarContrasena.vue";
import cambiarImagenComponent from "./cambiarImagen.vue";

export default {
  name: "datosCuenta",
  components: {
    menuPerfilComponent,
    modificarNombreComponent,
    modificarContrasenaComponent,
    cambiarImagenComponent
  },
  computed: {
    ...mapGetters(["userAvatar", "imageURL", "sessionToken"]),
  },
  watch: {
    sessionToken(value) {
      if (value) this.cargarDatosUsuario();
    },
  },
  data() {
    return {
      loading: false,
      nombreCompleto: "",
      nombre: "",
      paterno: "",
      materno: "",
      correo: "",
      discord: "",
      errorMessage: null,
      modificarNombre: false,
      modificarContrasena: false,
      cambiarImagen: false
    };
  },
  mounted() {
    if (this.sessionToken) this.cargarDatosUsuario();
  },
  methods: {
    async cargarDatosUsuario() {
      try {
        this.loading = true;
        const serverResponse = await getDatosUsuarioService();
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.nombreCompleto = serverResponse.usuario.nombreCompleto;
          this.nombre = serverResponse.usuario.nombre;
          this.paterno = serverResponse.usuario.paterno;
          this.materno = serverResponse.usuario.materno;
          this.correo = serverResponse.usuario.correo;
          this.discord = serverResponse.usuario.discord;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    nombreModificado(data) {
      this.modificarNombre = false;
      this.nombreCompleto = data.nombreCompleto;
      this.nombre = data.nombre;
      this.paterno = data.paterno;
      this.materno = data.materno;
    },
    contrasenaModificada() {
      this.modificarContrasena = false;
    },
  },
};
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}
</style>