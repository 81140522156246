<template>
  <v-dialog v-model="mostrar" width="500px" persistent>
    <v-card>
      <v-toolbar dense color="primary" dark flat class="text-h6"
        >Agregar hijo(a)</v-toolbar
      >
      <v-container style="margin-top: 15px">
        <v-text-field
          v-model.trim="correo"
          prepend-icon="mdi-at"
          name="correo"
          label="Correo"
          type="text"
          outlined
          dense
          :disabled="loading"
          :error-messages="correoErrors"
          @input="validarCorreo()"
          @blur="validarCorreo()"
        >
        </v-text-field>
      </v-container>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          outlined
          small
          :loading="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :disabled="loading"
          @click="agregar()"
          >Agregar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { agregarControlParentalService } from "./configuracion.service";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "agregarHijo",
  mixins: [validationMixin],
  props: ["mostrar"],
  validations: { correo: { required, email }, },
  data() {
    return {
      loading: false,
      correo: "",
      correoErrors: [],
    };
  },

  methods: {
    validarCorreo() {
      this.correoErrors = [];
      this.$v.correo.$touch();
      !this.$v.correo.required && this.correoErrors.push("Campo requerido.");
      !this.$v.correo.email && this.correoErrors.push("Correo no válido.");
    },
    async agregar() {
      this.validarCorreo();
      if (this.correoErrors.length == 0) {
        try {
          this.loading = true;
          const data = { correo: this.correo };
          const serverResponse = await agregarControlParentalService(data);
          this.loading = false;
          if (!serverResponse.ok)
            if (serverResponse.operational)
              this.correoErrors.push(serverResponse.mensaje);
            else this.$systemErrorMessage(serverResponse.mensaje);
          else {
            this.$emit("hijoAgregado", serverResponse.invitacion);
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },
  },
};
</script>
