<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar :color='color' dark dense flat class="text-h6">
        {{titulo}} invitación
      </v-toolbar>

      <v-container grid-list-md>
        <b>¿Está seguro de {{accion}} la invitación {{union}}</b>
        <br />
        {{ nombre }} ?<br /><br />
        <i>Esta acción no podrá ser revertida.</i>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            small
            :disabled="loading"
            @click="cerrarForm()"
          >
            Cancelar
          </v-btn>
          <v-btn
            :color="color"
            dark
            small
            :loading="loading"
            @click="realizarAccion()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from "vuex";
import { modificacionInvitacionParentalService, eliminarInvitacionParentalService } from './configuracion.service';

export default {
  props: {
    mostrar: Boolean,
    invitacion: Object,
    accion: String,
  },
  computed: {
    ...mapGetters(["sessionToken", "userId"]),
    nombre() {
      let nombre = this.userId == this.invitacion.hijo._id ? this.invitacion.usuario.nombreCompleto : this.invitacion.hijo.nombreCompleto;
      return nombre
    },
    union() {
      let union = this.userId == this.invitacion.hijo._id ? 'de' : 'a';
      return union
    }
  },
  data: () => ({
    loading: false,
    titulo: null,
    color: "danger",
    accionesModificar: ['reenviar', 'rechazar', 'aceptar'],
  }),
  created() {
    let tituloTmp = this.accion;
    this.titulo = tituloTmp.charAt(0).toUpperCase() + tituloTmp.slice(1);
    if (this.accion == 'reenviar' || this.accion == 'aceptar') this.color = 'primary';
  },
  methods: {
    realizarAccion() {
      if (this.accionesModificar.includes(this.accion)) this.modificarInvitacion();
      else this.eliminarModulo();
    },
    async modificarInvitacion() {
      try {
        this.loading = true;
        const data = {accion: this.accion}
        const serverResponse = await modificacionInvitacionParentalService(this.invitacion._id, data);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit('invitacionModificada', serverResponse.invitacion);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async eliminarModulo() {
      try {
        this.loading = true;
        const serverResponse = await eliminarInvitacionParentalService(this.invitacion._id);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit('invitacionEliminada', serverResponse.invitacion._id);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    cerrarForm() {
      this.$emit("cancelar");
    },
  },
};
</script>