import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500px","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"primary","dark":"","flat":""}},[_vm._v("Agregar hijo(a)")]),_c(VContainer,{staticStyle:{"margin-top":"15px"}},[_c(VTextField,{attrs:{"prepend-icon":"mdi-at","name":"correo","label":"Correo","type":"text","outlined":"","dense":"","disabled":_vm.loading,"error-messages":_vm.correoErrors},on:{"input":function($event){return _vm.validarCorreo()},"blur":function($event){return _vm.validarCorreo()}},model:{value:(_vm.correo),callback:function ($$v) {_vm.correo=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"correo"}})],1),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.agregar()}}},[_vm._v("Agregar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }