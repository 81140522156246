<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item @click="$emit('cambiarImagen')">
        <v-list-item-title>Cambiar imagen</v-list-item-title>
      </v-list-item>
      <v-divider class="mx-3"></v-divider>
      <v-list-item @click="$emit('modificarNombre')">
        <v-list-item-title>Modificar nombre</v-list-item-title>
      </v-list-item>
      <v-divider class="mx-3"></v-divider>
      <v-list-item @click="$emit('modificarContrasena')">
        <v-list-item-title>Cambiar contraseña</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "menuPerfil",
  components: {},
  data() {
    return {
      loading: false,
    };
  },
};
</script>
