<template>
  <v-container>
    <v-row>
      <v-col md="4" sm="12">
        <datosCuentaComponent />
      </v-col>
      <v-col md="8" sm="12">
        <configCuentaComponent />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import datosCuentaComponent from "./datosCuenta.vue";
import configCuentaComponent from "./configCuenta.vue";

export default {
  name: "perfilView",
  components: {
    datosCuentaComponent,
    configCuentaComponent,
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
