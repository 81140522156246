<template>
  <v-dialog v-model="mostrar" width="500px" persistent>
    <v-card>
      <v-toolbar dense color="primary" dark flat class="text-h6"
        >Modificar zona horaria</v-toolbar
      >
      <v-container
        style="margin-top: 15px"
        class="d-flex justify-space-around mb-6"
      >
        <v-col cols="12">
          <v-select
            v-model="selected"
            :items="items"
            hide-selected
            outlined
            dense
            label="Nueva zona horaria"
          ></v-select>
        </v-col>
      </v-container>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          outlined
          small
          :loading="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          @click="actualizarZona()"
          >Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { actualizarZonaService } from "./configuracion.service";

export default {
  name: "modificarZonaHoraria",
  props: ["mostrar", "zona"],
  computed: {
    ...mapGetters(["sessionToken"]),
  },
  created() {
    this.selected = this.zona;
  },
  data() {
    return {
      loading: false,
      selected: "",
      items: [
        "America/Tijuana",
        "America/Hermosillo",
        "America/Mexico_City",
        "America/Cancun",
      ],
    };
  },
  methods: {
    async actualizarZona() {
      try {
        this.loading = true;
        const sendData = {
          tipoZona: this.selected,
        };
        const serverResponse = await actualizarZonaService(
          this.sessionToken,
          sendData
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          let data = {
            tipoZona: serverResponse.nuevoTipoZona.timezone,
          };
          this.$emit("zonaModificada", data);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>