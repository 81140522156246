<template>
  <v-dialog v-model="mostrar" width="500px" persistent>
    <v-card>
      <v-toolbar dense color="primary" dark flat class="text-h6"
        >Agregar escuela</v-toolbar
      >
      <v-container style="margin-top: 15px">
        <v-text-field
          v-model.trim="clave"
          label="Clave"
          :disabled="loading"
          outlined
          dense
          @input="validarClave()"
          :error-messages="claveErrors"
          @blur="validarClave()"
        >
        </v-text-field>
      </v-container>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          outlined
          small
          :loading="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :disabled="loading"
          @click="agregar()"
          >Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { agregarEscuelaService } from "./configuracion.service";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "agregarEscuela",
  mixins: [validationMixin],
  props: ["mostrar"],
  validations: { clave: { required, minLength: minLength(3) } },
  data() {
    return {
      loading: false,
      clave: "",
      claveErrors: [],
    };
  },

  methods: {
    validarClave() {
      this.claveErrors = [];
      this.$v.clave.$touch();
      !this.$v.clave.required && this.claveErrors.push("Campo requerido.");
      !this.$v.clave.minLength &&
        this.claveErrors.push("Ingrese mínimo 3 caracteres.");
    },
    async agregar() {
      this.validarClave();
      if (this.claveErrors.length == 0) {
        try {
          this.loading = true;
          const data = { clave: this.clave };
          const serverResponse = await agregarEscuelaService(data);
          this.loading = false;
          if (!serverResponse.ok)
            if (serverResponse.operational)
              this.claveErrors.push(serverResponse.mensaje);
            else this.$systemErrorMessage(serverResponse.mensaje);
          else {
            this.$emit("escuelaAgregada", serverResponse.escuela);
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },
  },
};
</script>
