<template>
  <v-dialog v-model="mostrar" width="500px" persistent>
    <v-card>
      <v-toolbar dense color="primary" dark flat class="text-h6"
        >Cambiar Contraseña</v-toolbar
      >

      <v-container style="margin-top: 15px">
        <v-text-field
          name="login"
          type="password"
          label="Actual"
          v-model="actual"
          :disabled="loading"
          :error-messages="errors.actual"
          outlined
          dense
          @input="validarActual()"
          @blur="validarActual()"
        ></v-text-field>

        <v-text-field
          name="login"
          type="password"
          label="Nueva"
          v-model="password"
          :disabled="loading"
          :error-messages="errors.password"
          outlined
          dense
          @input="validarNuevaUno()"
          @blur="validarNuevaUno()"
        ></v-text-field>

        <v-text-field
          name="login"
          label="Confirmar nueva"
          type="password"
          v-model="repeatPassword"
          :disabled="loading"
          :error-messages="errors.repeatPassword"
          outlined
          dense
          @input="validarNuevaDos()"
          @blur="validarNuevaDos()"
        ></v-text-field>

        <span class="red--text" v-if="mensaje">{{ mensaje }}</span>
      </v-container>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{
            'disable-events':
              actual == null ||
              errors.actual.length ||
              errors.password.length ||
              errors.repeatPassword.length > 0,
          }"
          @click="actualizarContrasena()"
          >Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

import { actualizarContrasenaService } from "./perfil.service";

export default {
  name: "modificarContrasena",
  props: ["mostrar"],
  mixins: [validationMixin],
  validations: {
    actual: { required },
    password: { required, minLength: minLength(5) },
    repeatPassword: { required, sameAsPassword: sameAs("password") },
  },
  data() {
    return {
      loading: false,
      actual: null,
      password: null,
      repeatPassword: null,
      errorMessage: null,
      mensaje: null,
      errors: {
        actual: [],
        password: [],
        repeatPassword: [],
      },
      enviando: false,
    };
  },
  methods: {
    validarActual() {
      this.$v.actual.$touch();
      const errors = [];
      if (this.$v.actual.$dirty) {
        !this.$v.actual.required && errors.push("Campo requerido.");
      }
      this.errors.actual = errors;
    },
    validarNuevaUno() {
      this.$v.password.$touch();
      const errors = [];
      if (this.$v.password.$dirty) {
        !this.$v.password.minLength && errors.push("Mínimo 5 caracteres.");
        !this.$v.password.required && errors.push("Campo requerido.");
      }
      this.errors.password = errors;
    },
    validarNuevaDos() {
      this.$v.repeatPassword.$touch();
      const errors = [];
      if (this.$v.repeatPassword.$dirty) {
        !this.$v.repeatPassword.sameAsPassword &&
          errors.push("Deben ser iguales");
        !this.$v.repeatPassword.required && errors.push("Campo requerido.");
      }
      this.errors.repeatPassword = errors;
    },
    async actualizarContrasena() {
      try {
        this.loading = true;
        const sendData = {
          actual: this.actual,
          nuevo1: this.password,
          nuevo2: this.repeatPassword,
        };
        const serverResponse = await actualizarContrasenaService(sendData);
        this.loading = false;
        if (!serverResponse.ok) {
          if (serverResponse.operational && serverResponse.passwordError)
            this.errors.actual.push(serverResponse.mensaje);
          else this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.actual = null;
          this.password = null;
          this.repeatPassword = null;
          this.mensaje = null;
          this.$emit("contrasenaModificada");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
