import { postServerRequest, patchServerRequest } from '../../components/globals/services/serverRequest.service';
import { store } from '@/store/store';

const httpURL = store.getters.httpURL;

export async function getDatosUsuarioService() {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/perfil/datosUsuario`;
    const config = { headers: { token: sessionToken } };
    return await postServerRequest(url, {}, config);
}

export async function getConfiguracionUsuarioService() {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/perfil/configuracion`;
    const config = { headers: { token: sessionToken } };
    return await postServerRequest(url, {}, config);
}


export async function actualizarNombreService(data) {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/perfil/nombre`;
    const config = { headers: { token: sessionToken } };
    return await patchServerRequest(url, data, config);
}

export async function actualizarContrasenaService(data) {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/perfil/password`;
    const config = { headers: { token: sessionToken } };
    return await patchServerRequest(url, data, config);
}

export async function cambiarAvatarService(formData, uploadHandler) {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/perfil/avatar`;
    const config = {
        headers: {
            token: sessionToken,
            'Content-Type': 'multipart/form-data'
        },
        ...uploadHandler
    };
    return await patchServerRequest(url, formData, config);
}