<template>
  <div>
    <v-list-item>
      <v-list-item-icon>
        <v-icon color="primary">mdi-school-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-subtitle>Escuelas asociadas</v-list-item-subtitle>
        <v-list-item-subtitle v-if="escuelas.length == 0"
          ><i>No hay escuelas registradas</i></v-list-item-subtitle
        >

        <v-list-item-title
          v-else
          v-for="(escuela, index) in escuelasLocal"
          :key="index"
          >{{ escuela.nombre }}
          <v-list-item-subtitle
            ><i style="text-transform: capitalize">{{
              escuela.tipoUsuario
            }}</i></v-list-item-subtitle
          ></v-list-item-title
        >
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon color="primary" @click="agregarEscuela = true">
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <agregar-Escuela
      v-if="agregarEscuela"
      :mostrar="agregarEscuela"
      @cancelar="agregarEscuela = false"
      @escuelaAgregada="escuelaAgregada"
    />
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import agregarEscuela from "./agregarEscuela.vue";
import { cargarEscuelasService } from "./configuracion.service";
import _ from 'underscore';

export default {
  name: "escuelasItem",
  components: { agregarEscuela },
  computed: {
    ...mapGetters(["sessionToken"]),
    escuelasLocal() {
      const escuelas = [];
      if (this.escuelas.length > 0) {
        let ids = this.escuelas.map((x) => {
          return x._id;
        });
        ids = _.uniq(ids);
        for(const idEscuela of ids){
          const escuela = this.escuelas.find(x=>x._id == idEscuela);
          const usuarioEscuelas = this.escuelas.filter(x=>x._id == idEscuela);
          const roles = usuarioEscuelas.map(x=>{ return x.tipoUsuario});
          escuelas.push({
            nombre: escuela.nombre,
            tipoUsuario: roles.join(', ')
          })
        }
      }
      return escuelas;
    },
  },
  watch: {
    sessionToken: function (val) {
      if (val) this.cargarEscuelas();
    },
  },
  data() {
    return {
      loading: false,
      agregarEscuela: false,
      escuelas: [],
    };
  },
  mounted() {
    if (this.sessionToken) this.cargarEscuelas();
  },
  methods: {
    async cargarEscuelas() {
      try {
        this.loading = true;
        const serverResponse = await cargarEscuelasService();
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.escuelas = serverResponse.escuelas;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    escuelaAgregada(escuela) {
      this.agregarEscuela = false;
      this.escuelas.push(escuela);
    },
  },
};
</script>
