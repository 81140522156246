<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon color="primary">mdi-office-building</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-subtitle>Empresas asociadas</v-list-item-subtitle>
      <v-list-item-subtitle
        ><i>No hay empresas asociadas</i></v-list-item-subtitle
      >
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "empresasItem",
  components: {},
  data() {
    return {
      loading: false,
    };
  },
};
</script>
