<template>
  <v-dialog v-model="mostrar" width="500px" persistent>
    <v-card>
      <v-toolbar dense color="primary" dark flat class="text-h6"
        >Modificar nombre</v-toolbar
      >

      <v-container style="margin-top: 15px">
        <v-text-field
          v-model.trim="nombreLocal"
          label="Nombre"
          :disabled="loading"
          :error-messages="errors.nombre"
          outlined
          dense
          @input="validarNombre()"
          @blur="validarNombre()"
        >
        </v-text-field>

        <v-text-field
          label="Apellido Paterno"
          type="text"
          v-model.trim="paternoLocal"
          :disabled="loading"
          :error-messages="errors.paterno"
          outlined
          dense
          @input="validarPaterno()"
          @blur="validarPaterno()"
        ></v-text-field>
        <v-text-field
          type="text"
          label="Apellido Materno"
          v-model.trim="maternoLocal"
          :disabled="loading"
          :error-messages="errors.materno"
          outlined
          dense
          @input="validarMaterno()"
          @blur="validarMaterno()"
        ></v-text-field>
      </v-container>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{
            'disable-events':
              errors.nombre.length ||
              errors.paterno.length ||
              errors.materno.length > 0,
          }"
          @click="actualizarNombre()"
          >Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

import { actualizarNombreService } from "./perfil.service";

export default {
  name: "modificarNombre",
  props: ["mostrar", "nombre", "paterno", "materno"],
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["userId"]),
  },
  validations: {
    nombreLocal: { required, minLength: minLength(2) },
    paternoLocal: { required, minLength: minLength(1) },
    maternoLocal: { required, minLength: minLength(1) },
  },
  data() {
    return {
      loading: false,
      nombreLocal: "",
      maternoLocal: "",
      paternoLocal: "",
      errorMessage: null,
      errors: {
        nombre: [],
        paterno: [],
        materno: [],
      },
    };
  },
  created() {
    this.nombreLocal = this.nombre;
    this.paternoLocal = this.paterno;
    this.maternoLocal = this.materno;
  },
  methods: {
    validarNombre() {
      this.$v.nombreLocal.$touch();
      const errors = [];
      if (this.$v.nombreLocal.$dirty) {
        !this.$v.nombreLocal.required && errors.push("Campo requerido.");
        !this.$v.nombreLocal.minLength && errors.push("Mínimo 2 caracteres.");
      }
      this.errors.nombre = errors;
    },
    validarPaterno() {
      this.$v.paternoLocal.$touch();
      const errors = [];
      if (this.$v.paternoLocal.$dirty) {
        !this.$v.paternoLocal.minLength && errors.push("Mínimo 1 caracteres.");
        !this.$v.paternoLocal.required && errors.push("Campo requerido.");
      }
      this.errors.paterno = errors;
    },
    validarMaterno() {
      this.$v.maternoLocal.$touch();
      const errors = [];
      if (this.$v.maternoLocal.$dirty) {
        !this.$v.maternoLocal.minLength && errors.push("Mínimo 1 caracteres.");
        !this.$v.maternoLocal.required && errors.push("Campo requerido.");
      }
      this.errors.materno = errors;
    },
    async actualizarNombre() {
      try {
        this.loading = true;
        const sendData = {
          nombre: this.nombreLocal,
          paterno: this.paternoLocal,
          materno: this.maternoLocal,
        };
        const serverResponse = await actualizarNombreService(sendData);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          let data = {
            nombreCompleto: serverResponse.nuevoNombre.nombreCompleto,
            nombre: serverResponse.nuevoNombre.nombre,
            paterno: serverResponse.nuevoNombre.paterno,
            materno: serverResponse.nuevoNombre.materno,
          };
          this.$emit("nombreModificado", data);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
