<template>
  <v-dialog v-model="mostrar" persistent max-width="400">
    <v-card class="mx-auto" :loading="loading">
      <v-container class="pa-2" fluid>
        <croppa
          v-model="myCroppa"
          accept=".jpeg, .png, .jpg"
          :width="380"
          :height="380"
          :disabled="loading"
          placeholder="Seleccionar imagen"
          :placeholder-font-size="18"
          :prevent-white-space="true"
          @file-choose="mostrarAcciones = true"
          :showRemoveButton="false"
        />

        <v-card-actions v-if="mostrarAcciones">
          <v-btn-toggle>
            <v-btn small :disabled="loading" @click="limpiarImagen()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn small :disabled="loading" @click="myCroppa.rotate(-1)">
              <v-icon>mdi-rotate-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn small :disabled="loading" @click="myCroppa.rotate()">
              <v-icon>mdi-rotate-right</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn small :disabled="loading" @click="myCroppa.flipX()">
              <v-icon>mdi-gesture-swipe-right</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn small :disabled="loading" @click="myCroppa.flipY()">
              <v-icon>mdi-gesture-swipe-up</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
        <v-divider class="mx-3"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="loading"
            small
            outlined
            @click="$emit('cancelar')"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            :loading="loading"
            small
            :class="{'disable-btn':!mostrarAcciones}"
            @click="prepararImagen()"
            >Guardar</v-btn
          >
        </v-card-actions>
        <v-progress-linear
          v-if="loading"
          :value="progreso"
          :max="100"
          show-progress
          animated
        ></v-progress-linear>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";

import { cambiarAvatarService } from "./perfil.service";

export default {
  name: "cambiarImagen",
  props: { mostrar: Boolean },

  computed: {
    ...mapGetters(["userAvatar"]),
  },
  data() {
    return {
      loading: false,
      myCroppa: {},
      vacio: false,
      noValido: false,
      mostrarAcciones: false,
      progreso: 0,
    };
  },
  methods: {
    async getBlobImage() {
      return new Promise((resolve, reject) => {
        this.myCroppa.generateBlob((blob) => resolve(blob), "image/jpeg", 0.8);
      });
    },
    async prepararImagen() {
      try {
        const chosenFile = this.myCroppa.getChosenFile()
        var blobFile = await this.myCroppa.promisedBlob(chosenFile.type, 0.8)
        var imageFile = new File([blobFile], chosenFile.name, {type: blobFile.type});
        var form = new FormData();
        form.append("image", imageFile);
        this.enviarImagen(form);
      } catch (error) {
        console.log(error);
        this.$systemErrorMessage("Imagen no válida");
      }
    },
    async enviarImagen(formData) {
      try {
        const uploadHandler = {
          onUploadProgress: (progressEvent) =>
            (this.progreso =
              (progressEvent.loaded * 100) / progressEvent.total),
        };

        this.loading = true;
        const serverResponse = await cambiarAvatarService(formData, uploadHandler);
        this.loading = false;
        this.progreso = 0;
        if (!serverResponse.ok) this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$store.dispatch("setUserAvatar", serverResponse.avatar);
          this.myCroppa.remove();
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        if(!error.handdled) this.$appErrorMessage();
      }
    },
    cancelar() {
      this.myCroppa.remove();
      this.$emit("cancelar");
    },
    limpiarImagen() {
      this.myCroppa.remove();
      this.mostrarAcciones = false;
    },
  },
};
</script>
