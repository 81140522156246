import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-account")])],1),_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Tipo de sesión")]),_c(VListItemTitle,[_vm._v(_vm._s(_vm.tipoSesionString))])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.mostrar = true}}},[_c(VIcon,[_vm._v("mdi-pencil-circle-outline")])],1)],1),(_vm.mostrar)?_c('modificarSesionComponent',{attrs:{"mostrar":_vm.mostrar,"sesion":_vm.sesion},on:{"cancelar":function($event){_vm.mostrar = false},"sesionModificada":_vm.sesionModificada}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }