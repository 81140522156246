<template>
  <v-dialog v-model="mostrar" width="500px" persistent>
    <v-card>
      <v-toolbar dense color="primary" dark flat class="text-h6"
        >{{titulo}}</v-toolbar
      >
      <v-container>
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="selected" value="profesor"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Profesor</v-list-item-title>
              <v-list-item-subtitle
                >El usuario podrá crear cursos, aplicar exámenes,
                etc.</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-action>
              <v-checkbox v-model="selected" value="alumno"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Alumno</v-list-item-title>
              <v-list-item-subtitle
                >El usuario podrá registrarse a cursos, constestar exámenes,
                etc.</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-action>
              <v-checkbox v-model="selected" value="padre"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Control parental</v-list-item-title>
              <v-list-item-subtitle
                >El usuario podrá visualizar el rendimiento de otros usuarios
                con previo consentimiento.</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          outlined
          small
          :loading="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          small
          :loading="loading"
          :disabled="selected.length == 0"
          @click="actualizarSesion()"
          >Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { actualizarSesionService } from "./configuracion.service";

export default {
  name: "modificarTipoSesion",
  props: {
    titulo:{ type: String, default: () => "Tipo sesión" },
    mostrar: { type: Boolean},
    sesion: { type: Array, default: () => []}
  },
  computed: {
    ...mapGetters(["tipoSesion", "sessionToken"]),
  },
  data() {
    return {
      loading: false,
      selected: this.sesion,
    };
  },
  created(){
    this.selected = this.sesion;
  },
  methods: {
    async actualizarSesion() {
      try {
        this.loading = true;
        const sendData = { tipoSesion: this.selected };
        const serverResponse = await actualizarSesionService(
          this.sessionToken,
          sendData
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          let data = {
            tipoSesion: serverResponse.nuevoTipoSesion.tipoSesion,
          };
          this.$emit("sesionModificada", data);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
