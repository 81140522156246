import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500px","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"primary","dark":"","flat":""}},[_vm._v("Agregar escuela")]),_c(VContainer,{staticStyle:{"margin-top":"15px"}},[_c(VTextField,{attrs:{"label":"Clave","disabled":_vm.loading,"outlined":"","dense":"","error-messages":_vm.claveErrors},on:{"input":function($event){return _vm.validarClave()},"blur":function($event){return _vm.validarClave()}},model:{value:(_vm.clave),callback:function ($$v) {_vm.clave=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"clave"}})],1),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.agregar()}}},[_vm._v("Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }