<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon color="primary">mdi-map-clock-outline</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-subtitle>Zona horaria</v-list-item-subtitle>
      <v-list-item-title>{{ zona }}</v-list-item-title>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn icon color="primary" @click="mostrar = true">
        <v-icon>mdi-pencil-circle-outline</v-icon>
      </v-btn>
    </v-list-item-action>

    <modificarZonaComponent
      v-if="mostrar"
      :mostrar="mostrar"
      :zona="zona"
      @cancelar="mostrar = false"
      @zonaModificada="zonaModificada"
    />
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import modificarZonaComponent from "./modificarZonaHoraria.vue";

export default {
  name: "zonaHorariaItem",
  components: {
    modificarZonaComponent,
  },
  computed: {
    ...mapGetters(["timezone", "sessionToken"]),
  },
  watch: {
    timezone(value) {
      if (value) this.zona = this.timezone;
    },
  },
  data() {
    return {
      loading: false,
      mostrar: false,
      zona: "",
    };
  },
  mounted() {
    if (this.timezone) this.zona = this.timezone;
  },
  methods: {
    zonaModificada(data) {
      this.mostrar = false;
      this.zona = data.tipoZona;
    },
  },
};
</script>
