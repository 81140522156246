import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-school-outline")])],1),_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Escuelas asociadas")]),(_vm.escuelas.length == 0)?_c(VListItemSubtitle,[_c('i',[_vm._v("No hay escuelas registradas")])]):_vm._l((_vm.escuelasLocal),function(escuela,index){return _c(VListItemTitle,{key:index},[_vm._v(_vm._s(escuela.nombre)+" "),_c(VListItemSubtitle,[_c('i',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(escuela.tipoUsuario))])])],1)})],2),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.agregarEscuela = true}}},[_c(VIcon,[_vm._v("mdi-plus-circle-outline")])],1)],1)],1),(_vm.agregarEscuela)?_c('agregar-Escuela',{attrs:{"mostrar":_vm.agregarEscuela},on:{"cancelar":function($event){_vm.agregarEscuela = false},"escuelaAgregada":_vm.escuelaAgregada}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }