<template>
  <div>
    <v-list-item>
      <v-list-item-icon>
        <v-icon color="primary">mdi-account-supervisor-circle</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-subtitle style="display:flex; justify-content: space-between">
          Control parental
          <v-list-item-action v-if="hijosLocal.length > 0" style="margin: 0px">
            <v-btn icon color="primary" @click="agregarHijo = true">
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item-subtitle>

        <v-list-item-subtitle v-if="invitacionesHijoLocal.length == 0 && invitacionesPadreLocal.length == 0">
          <i>No hay hijos/padres registrados</i>
        </v-list-item-subtitle>

        <!-- ---- -->
        <div >
          <v-list-item-title
          v-for="(invitacion, index) in invitacionesHijoLocal"
          :key="index"
          >{{ invitacion.usuario.nombreCompleto }}
            <v-list-item-subtitle>
              <i style="text-transform: capitalize">{{invitacion.leyenda}}</i>
              <v-list-item-action style="margin: 0px; display: inline" v-if="invitacion.aceptada == null">
                <v-btn v-if="invitacion.aceptada == null" class="margenButton" x-small outlined color="red" @click="accionARealizar(invitacion,'rechazar')">Rechazar</v-btn>
                <v-btn v-if="invitacion.aceptada == null" class="margenButton" x-small dark color="primary" @click="accionARealizar(invitacion,'aceptar')">Aceptar</v-btn>
              </v-list-item-action>
            </v-list-item-subtitle>
          </v-list-item-title>
        </div>

        <div>
            <v-list-item-title
            v-for="(invitacion, index) in invitacionesPadreLocal"
            :key="index"
            >{{ invitacion.hijo.nombreCompleto }}
            <v-list-item-subtitle>
              <i style="text-transform: capitalize">{{invitacion.leyenda}}</i>
              <v-list-item-action style="margin: 0px; display: inline">
              <v-btn v-if="invitacion.aceptada == null" class="margenButton" x-small outlined color="primary" @click="accionARealizar(invitacion,'cancelar')">Cancelar invitación</v-btn>
              <v-btn v-if="invitacion.aceptada == false" class="margenButton" x-small outlined color="red" @click="accionARealizar(invitacion,'eliminar')">Eliminar invitación</v-btn>
              <v-btn v-if="invitacion.aceptada == false" class="margenButton" x-small dark color="primary" @click="accionARealizar(invitacion,'reenviar')">Reenviar</v-btn>
            </v-list-item-action>
            </v-list-item-subtitle>
          </v-list-item-title>
        </div>
        <!-- ---- -->

      </v-list-item-content>
      <v-list-item-action v-if="hijosLocal.length == 0" style="margin: 0px">
        <v-btn icon color="primary" @click="agregarHijo = true">
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <agregarHijoComponent
      v-if="agregarHijo"
      :mostrar="agregarHijo"
      @cancelar="agregarHijo = false"
      @hijoAgregado="hijoAgregado"
    />
    <accionInvitacionComponent
      v-if="realizarAccion"
      :mostrar="realizarAccion"
      :invitacion="invitacion"
      :accion="accion"
      @cancelar="realizarAccion = false"
      @invitacionEliminada="invitacionEliminada"
      @invitacionModificada="invitacionModificada"
    />
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import agregarHijoComponent from "./agregarHijo.vue";
import accionInvitacionComponent from "./accionInvitacion.vue"
import { getAllInvitacionesService } from './configuracion.service';

export default {
  name: "controlParentalItem",
  components: {
    agregarHijoComponent,
    accionInvitacionComponent
  },
  computed: {
    ...mapGetters(["sessionToken", "userId"]),
    hijosLocal() {
      let hijos = [];
      if (this.hijos.length > 0) {
        for(const hijo of this.hijos){
          if (hijo.aceptada == null)
            hijo.leyenda = "Invitación no aceptada"
          else if(hijo.aceptada == false)
            hijo.leyenda = "Invitación rechazada"
          else 
            hijo.leyenda = "Invitación aceptada"

          hijos.push(hijo);
        } 
      }
      return hijos
    },
    invitacionesHijoLocal() {
      let invitaciones = [];
      if (this.invitacionesHijo.length > 0) {
        for(const invitacion of this.invitacionesHijo){
          if(invitacion.hijo._id == this.userId){
            if (invitacion.aceptada == null)
              invitacion.leyenda = "Quiere agregarte como control parental"
            else if(invitacion.aceptada == false)
              invitacion.leyenda = "Invitación rechazada"
            else 
              invitacion.leyenda = "Padre/Madre"

            invitaciones.push(invitacion);
          }
        } 
      }
      return invitaciones
    },
    invitacionesPadreLocal() {
      let invitaciones = [];
      if (this.invitacionesPadre.length > 0) {
        for(const invitacion of this.invitacionesPadre){
          if (invitacion.aceptada == null && (invitacion.usuario._id == this.userId || invitacion.usuario == this.userId))
            invitacion.leyenda = "Invitación no aceptada"
          else if(invitacion.aceptada == false && invitacion.usuario._id == this.userId)
            invitacion.leyenda = "Invitación rechazada"
          else if(invitacion.aceptada == true && invitacion.usuario._id == this.userId )
            invitacion.leyenda = "Hijo/Hija"

          invitaciones.push(invitacion);
        } 
      }
      return invitaciones
    },
  },
  watch: {
    sessionToken: function (val) {
      if (val) this.cargarInvitaciones();
    },
  },
  data() {
    return {
      loading: false,
      agregarHijo: false,
      realizarAccion: false,
      invitacion: null,
      accion: null,
      hijos: [],
      invitacionesHijo: [],
      invitacionesPadre: [],
    };
  },
  mounted() {
    if (this.sessionToken) this.cargarInvitaciones();
  },
  methods: {
    async cargarInvitaciones() {
      try {
        this.loading = true;
        const serverResponse = await getAllInvitacionesService();
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.hijos = serverResponse.invitaciones;
          let invitaciones = serverResponse.invitaciones;
          invitaciones.map( i => {
              if(i.hijo._id == this.userId) this.invitacionesHijo.push(i)
              else this.invitacionesPadre.push(i)
          });
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    hijoAgregado(invitacion) {
      this.agregarHijo = false;
      this.invitacionesPadre.push(invitacion);
    },
    accionARealizar(invitacion, accion) {
      this.realizarAccion = true;
      this.invitacion = invitacion;
      this.accion = accion;
    },
    invitacionEliminada(idInvitacion) {
      const index = this.invitacionesPadre.findIndex((x) => x._id == idInvitacion);
      this.invitacionesPadre.splice(index, 1);
    },
    invitacionModificada(invitacion){
      const indexP = this.invitacionesPadre.findIndex((x) => x._id == invitacion._id);
      const indexH = this.invitacionesHijo.findIndex((x) => x._id == invitacion._id);
      if(indexP > -1) this.invitacionesPadre[indexP].aceptada = invitacion.aceptada;
      if(indexH > -1) this.invitacionesHijo[indexH].aceptada = invitacion.aceptada;
    },
  },
};
</script>
<style>
  .margenButton {
    margin: 0px 5px 0px 5px;
  }
</style>