<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon color="primary">mdi-clipboard-check-outline</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-subtitle>Fecha de registro</v-list-item-subtitle>
      <v-list-item-title>{{ fechaRegistro }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "fechaRegistroItem",
  props: ["registro"],
  components: {},
  computed: {
    fechaRegistro() {
      let fecha = "No disponible";
      if (this.registro) {
        fecha = moment
          .tz(this.registro, this.timezone)
          .format("DD [de] MMMM [de] YYYY");
      }
      return fecha;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
