import { patchServerRequest, getServerRequest, deleteServerRequest } from '../../../components/globals/services/serverRequest.service';
import { store } from '@/store/store';

const httpURL = store.getters.httpURL;

export async function actualizarSesionService(sessionToken, data) {
    const url = `${httpURL}/perfil/sesion`;
    const config = { headers: { token: sessionToken }};
    return await patchServerRequest(url, data, config);
}

export async function actualizarZonaService(sessionToken, data) {
    const url = `${httpURL}/perfil/tipoZona`;
    const config = { headers: { token: sessionToken }};
    return await patchServerRequest(url, data, config);
}

export async function cargarEscuelasService() {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/perfil/configuracion/escuelas`;
    return await getServerRequest(url, config);
}

export async function agregarEscuelaService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token:token }};
    const url = `${httpURL}/perfil/configuracion/escuela`;
    return await patchServerRequest(url, data, config);
}

export async function agregarControlParentalService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token:token }};
    const url = `${httpURL}/perfil/configuracion/control/parental`;
    return await patchServerRequest(url, data, config);
}

export async function cargarInvitacionesService() {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/perfil/configuracion/control/parental/invitaciones`;
    return await getServerRequest(url, config);
}

export async function getAllInvitacionesService() {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/perfil/configuracion/control/parental/invitaciones/all`;
    const config = { headers: { token: sessionToken } }
    return await getServerRequest(url, config);
}

export async function modificacionInvitacionParentalService(idInvitacion, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token:token }};
    const url = `${httpURL}/perfil/configuracion/control/parental/modificar/invitaciones/${idInvitacion}`;
    return await patchServerRequest(url, data, config);
}

export async function eliminarInvitacionParentalService(idInvitacion) {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/perfil/configuracion/control/parental/eliminar/invitaciones/${idInvitacion}`;
    return await deleteServerRequest(url, config);
}