<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon color="primary">mdi-account</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-subtitle>Tipo de sesión</v-list-item-subtitle>
      <v-list-item-title>{{ tipoSesionString }}</v-list-item-title>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn icon color="primary" @click="mostrar = true">
        <v-icon>mdi-pencil-circle-outline</v-icon>
      </v-btn>
    </v-list-item-action>

    <modificarSesionComponent
      v-if="mostrar"
      :mostrar="mostrar"
      :sesion="sesion"
      @cancelar="mostrar = false"
      @sesionModificada="sesionModificada"
    />
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import modificarSesionComponent from "./modificarTipoSesion.vue";

export default {
  name: "tipoSesionItem",
  components: {
    modificarSesionComponent,
  },
  computed: {
    ...mapGetters(["tipoSesion", "sessionToken"]),
    tipoSesionString() {
      const tipos = this.sesion.toString().split(",");
      const tiposCap = tipos.map(x=>{
        if(x == 'profesor') return 'Profesor';
        if(x == 'alumno') return 'Alumno';
        if(x == 'padre') return 'Control parental';
        return 'Usuario'
      })
      return tiposCap.join(", ");
      
    },
  },
  watch: {
    tipoSesion(value) {
      if (value) this.cargarSesion();
    },
  },
  data() {
    return {
      loading: false,
      mostrar: false,
      sesion: "",
    };
  },
  mounted() {
    if (this.tipoSesion) this.cargarSesion();
  },
  methods: {
    cargarSesion() {
      this.sesion = this.tipoSesion;
    },
    sesionModificada(data) {
      this.mostrar = false;
      this.$store.dispatch("setTipoSesion", data.tipoSesion);
    },
  },
};
</script>
