import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500px","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"primary","dark":"","flat":""}},[_vm._v("Modificar nombre")]),_c(VContainer,{staticStyle:{"margin-top":"15px"}},[_c(VTextField,{attrs:{"label":"Nombre","disabled":_vm.loading,"error-messages":_vm.errors.nombre,"outlined":"","dense":""},on:{"input":function($event){return _vm.validarNombre()},"blur":function($event){return _vm.validarNombre()}},model:{value:(_vm.nombreLocal),callback:function ($$v) {_vm.nombreLocal=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombreLocal"}}),_c(VTextField,{attrs:{"label":"Apellido Paterno","type":"text","disabled":_vm.loading,"error-messages":_vm.errors.paterno,"outlined":"","dense":""},on:{"input":function($event){return _vm.validarPaterno()},"blur":function($event){return _vm.validarPaterno()}},model:{value:(_vm.paternoLocal),callback:function ($$v) {_vm.paternoLocal=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"paternoLocal"}}),_c(VTextField,{attrs:{"type":"text","label":"Apellido Materno","disabled":_vm.loading,"error-messages":_vm.errors.materno,"outlined":"","dense":""},on:{"input":function($event){return _vm.validarMaterno()},"blur":function($event){return _vm.validarMaterno()}},model:{value:(_vm.maternoLocal),callback:function ($$v) {_vm.maternoLocal=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"maternoLocal"}})],1),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{class:{
          'disable-events':
            _vm.errors.nombre.length ||
            _vm.errors.paterno.length ||
            _vm.errors.materno.length > 0,
        },attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.actualizarNombre()}}},[_vm._v("Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }