<template>
  <v-card shaped>
    <v-container>
      <v-list>
        <v-subheader>Configuración</v-subheader>
        <v-divider class="mx-3"></v-divider>
        <tipoSesionItemComponent />
        <fechaRegistroItemComponent :registro="registro" />
        <zonaHorariaItemComponent />
        <empresasItemComponent />
        <escuelasItemComponent />
        <controlParentalItemComponent />
      </v-list>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getConfiguracionUsuarioService } from "./perfil.service";

import tipoSesionItemComponent from "./configuracion/tipoSesionItem.vue";
import fechaRegistroItemComponent from "./configuracion/fechaRegistroItem.vue";
import zonaHorariaItemComponent from "./configuracion/zonaHorariaItem.vue";
import empresasItemComponent from "./configuracion/empresasItem.vue";
import escuelasItemComponent from "./configuracion/escuelasItem.vue";
import controlParentalItemComponent from "./configuracion/controlParentalItem.vue";

export default {
  name: "configCuenta",
  components: {
    tipoSesionItemComponent,
    fechaRegistroItemComponent,
    zonaHorariaItemComponent,
    empresasItemComponent,
    escuelasItemComponent,
    controlParentalItemComponent,
  },
  computed: {
    ...mapGetters(["sessionToken"]),
  },
  watch: {
    sessionToken(value) {
      if (value) this.cargarConfiguracionUsuario();
    },
  },
  data() {
    return {
      loading: false,
      registro: "",
    };
  },
  mounted() {
    if (this.sessionToken) this.cargarConfiguracionUsuario();
  },
  methods: {
    async cargarConfiguracionUsuario() {
      try {
        this.loading = true;
        const serverResponse = await getConfiguracionUsuarioService();
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.registro = serverResponse.usuario.fechaRegistro;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}
</style>
